import React, { useContext, useEffect } from "react";
import { Container, Col, Row } from "reactstrap";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import event from "../../assets/img/B_EVENT.png";
import DiaccoInvestFooter from "./DiaccoInvestFooter/DiaccoInvestFooter";
import Header from "../Header/Header";
import Paper from "../../assets/img/paper.png";
import Buisness from "../../assets/img/professional.png";
import DiaccoInvestCss from "./DiaccoInvest.module.css";
import DetailCss from "./DiaccoInvestDetail.module.css";
import ModalStepContext from "../../core/context/ModalStepContext";
import ModalShowContext from "../../core/context/ModalShowContext";
import DataProcessingModal from "../common/Modal/DataProcessingModal/DataProcessingModal";
import NewsSlider from "./NewsSlider/NewsSlider";
import InvitationModal from "../common/Modal/InvitationModal/InvitationModal";
import JobModal from "../common/Modal/JobModal/JobModal";
import EstablishModal from "../common/Modal/EstablishModal/EstablishModal";
import DiaccoInvestHeader from "./DiaccoInvestHeader/DiaccoInvestHeader";
const DiaccoInvest = () => {
  const { currentStep, setStep } = useContext(ModalStepContext);
  const { show, setShow } = useContext(ModalShowContext);

  const showHide = show ? "d-block" : "d-none";

  const showModal = () => {
    setShow(!show);
    setStep(1);
  };

  useEffect(() => {
    setStep(1);
    setShow(false);
  }, []);
  return (
    <div className={`${DiaccoInvestCss.DiaccoInvestBg}`}>
      <div className={`${showHide} ${DetailCss.overlayWrapper}`}>
        <div className={`${DetailCss.overlay}`}></div>
        <a
          onClick={() => showModal()}
          className={`${DetailCss.closeButton}`}
          role="button"
        >
          <svg
            className={`${DetailCss.closeIcon}`}
            xmlns="http://www.w3.org/2000/svg"
            width="57.276"
            height="57.276"
            viewBox="0 0 57.276 57.276"
          >
            <g
              id="Group_1"
              data-name="Group 1"
              transform="translate(-593.801 -249.423)"
            >
              <line
                id="Line_1"
                data-name="Line 1"
                y2="70"
                transform="translate(647.188 253.312) rotate(45)"
                fill="none"
                stroke="#f61111"
                stroke-width="11"
              />
              <line
                id="Line_2"
                data-name="Line 2"
                y2="70"
                transform="translate(597.69 253.312) rotate(-45)"
                fill="none"
                stroke="#f61111"
                stroke-width="11"
              />
            </g>
          </svg>
        </a>
        <div className={`${DetailCss.overlayWindow}`}>
          <div className={`${DetailCss.setBg}`}>
            {currentStep === 1 && <DataProcessingModal />}
            {currentStep === 2 && <InvitationModal />}
            {currentStep === 3 && <JobModal />}
            {currentStep === 4 && <EstablishModal />}
          </div>
        </div>
      </div>

      <div className={`${DiaccoInvestCss.Banner}`}>
        <DiaccoInvestHeader />

        <h1>
          We will{" "}
          <strong>
            <span>t</span>
            <span className={`${DiaccoInvestCss.upText}`}>a</span>
            <span>k</span>e <span>c</span>
            <span className={`${DiaccoInvestCss.upText}`}>a</span>
            <span>r</span>
            <span>e</span>
          </strong>{" "}
          of
          <br />
          Your business administration
        </h1>
      </div>

      <div className={`${DiaccoInvestCss.AboutUs}`}>
        <div className={`${DiaccoInvestCss.ContentHolder}`}>
          <div className={`${DiaccoInvestCss.Description}`}>
            <h2 class="main-title whitespace-no-wrap font-bold text-center">
              About us
            </h2>

            <div>
              <p>
                DIACCO INVEST s.r.o., is the core of our corporate business
                <br />
                ideology, dedicated to expanding global businesses,
                administrative
                <br />
                services for international trade, domestic business development,
                and
                <br />
                ultimately job creations by providing unique services and
                particular
                <br />
                solutions to help meet the needs of each of our global clients.
              </p>
            </div>
          </div>

          <Container>
            <Row className={`${DiaccoInvestCss.BuisnessItem}`}>
              <Col lg={3}>
                <div className={`${DiaccoInvestCss.ImageHolder}`}>
                  <img src={Paper} alt="" />
                  <h4>Expansion of Businesses</h4>
                </div>
              </Col>
              <Col lg={9}>
                <p className={`${DiaccoInvestCss.DescriptionParagraph}`}>
                  If you are planning to expand your commercial activities in
                  your home country or if you are interested in establishing a
                  new branch in the Czech Republic, then we are able to help you
                  achieve your goals. We will stand by you from the start to the
                  end by submitting all the necessary paperwork on your behalf,
                  obtaining all the legal rights and authorisations and in every
                  step of the way until your business becomes fully operational.
                </p>
              </Col>
            </Row>
            <Row className={`${DiaccoInvestCss.BuisnessItem}`}>
              <Col lg={3}>
                <div className={`${DiaccoInvestCss.ImageHolder}`}>
                  <img src={Buisness} alt="" />
                  <h4>Participate in projects</h4>
                </div>
              </Col>
              <Col lg={9}>
                <p className={`${DiaccoInvestCss.DescriptionParagraph}`}>
                  If you don’t have what it takes to establish your own
                  commercial plan, then we invite you to come and talk with us.
                  At DIACCO INVEST, we are able to participate and cooperate in
                  your project and make it happen for you.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className={`${DiaccoInvestCss.News}`}>
        <h1>Latest news</h1>
        <div className={`${DiaccoInvestCss.ContentHolder}`}>
          <NewsSlider />
        </div>
      </div>
      <div className={`${DiaccoInvestCss.FormSection}`}>
        <div className={`${DiaccoInvestCss.ContentHolder}`}>
          <div className={`${DiaccoInvestCss.FormHolder}`}>
            <Formik
              initialValues={{
                Name: "",
                Email: "",
                Description: "",
                UserAgreement: false,
              }}
              validationSchema={Yup.object().shape({
                Name: Yup.string()
                  .min(2, "at least 2 characters")
                  .max(20, "too long")
                  .required("Value is required and can't be empty"),
                Email: Yup.string()
                  .email("invalid email")
                  .required("Value is required and can't be empty"),
                Description: Yup.string()
                  .min(5, "at least 5 characters")
                  .max(100, "too long")
                  .required("Value is required and can't be empty"),
                UserAgreement: Yup.bool().oneOf(
                  [true],
                  "Please read and confirm the agreement above"
                ),
              })}
              onSubmit={(values) => {
                alert("a");
              }}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className={`${DiaccoInvestCss.formGroup}`}>
                    <div>
                      <Field
                        type="text"
                        id="Name"
                        name="Name"
                        as=""
                        placeholder="Full name"
                      />

                      <ErrorMessage
                        className={`${DiaccoInvestCss.ErrorMessage}`}
                        name="Name"
                        component="div"
                      />
                    </div>
                    <div>
                      <Field
                        type="text"
                        id="Email"
                        name="Email"
                        as=""
                        placeholder="Email address"
                      />
                      <ErrorMessage
                        className={`${DiaccoInvestCss.ErrorMessage}`}
                        name="Email"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className={`${DiaccoInvestCss.FormSingle}`}>
                    <Field
                      type="text"
                      id="Description"
                      name="Description"
                      as="textarea"
                      placeholder="Description . . ."
                    />
                    <ErrorMessage
                      className={`${DiaccoInvestCss.ErrorMessage}`}
                      name="Description"
                      component="div"
                    />
                  </div>

                  <p>
                    <Field
                      type={"checkbox"}
                      id={"UserAgreement"}
                      name={"UserAgreement"}
                      as=""
                      placeholder=""
                    />
                    {"  "}I agree to the{" "}
                    <span
                      className={`${DiaccoInvestCss.DataProcessing}`}
                      onClick={() => {
                        setShow(true);
                        setStep(1);
                      }}
                    >
                      processing of personal data
                    </span>
                    <ErrorMessage
                      className={`${DiaccoInvestCss.ErrorMessage}`}
                      name="UserAgreement"
                      component="div"
                    />
                    <br />
                    <input
                      className={`${DiaccoInvestCss.Submit}`}
                      type="submit"
                      value="SUBMIT"
                    />
                  </p>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <DiaccoInvestFooter />
    </div>
  );
};

export default DiaccoInvest;
