import React from "react";
import event from "../../assets/img/B_EVENT.png";
import Header from "../Header/Header";
import Slider from "../Slider/Slider";
import landingCss from "./Landing.module.css";

const Landing = () => {
  return (
    <div className={`${landingCss.landingBg}`}>
      <Header />
      <Slider />
    </div>
  );
};

export default Landing;
