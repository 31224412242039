import React, { useContext, useEffect } from "react";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import event from "../../assets/img/B_EVENT.png";
import ContactUsFooter from "./ContactUsFooter/ContactUsFooter";
import Header from "../Header/Header";
import Slider from "../Slider/Slider";
import ContactUsCss from "./ContactUs.module.css";
import DetailCss from "./ContactUsDetail.module.css";
import ModalStepContext from "../../core/context/ModalStepContext";
import ModalShowContext from "../../core/context/ModalShowContext";
import DataProcessingModal from "../common/Modal/DataProcessingModal/DataProcessingModal";
const ContactUs = () => {
  const { currentStep, setStep } = useContext(ModalStepContext);
  const { show, setShow } = useContext(ModalShowContext);

  const showHide = show ? "d-block" : "d-none";

  const showModal = () => {
    setShow(!show);
    setStep(1);
  };

  useEffect(() => {
    setStep(1);
    setShow(false);
  }, []);
  return (
    <div className={`${ContactUsCss.ContactUsBg}`}>
      <Header />
      <div className={`${ContactUsCss.ContentHolder}`}>
        <div className={`${showHide} ${DetailCss.overlayWrapper}`}>
          <div className={`${DetailCss.overlay}`}></div>
          <a
            onClick={() => showModal()}
            className={`${DetailCss.closeButton}`}
            role="button"
          >
            <svg
              className={`${DetailCss.closeIcon}`}
              xmlns="http://www.w3.org/2000/svg"
              width="57.276"
              height="57.276"
              viewBox="0 0 57.276 57.276"
            >
              <g
                id="Group_1"
                data-name="Group 1"
                transform="translate(-593.801 -249.423)"
              >
                <line
                  id="Line_1"
                  data-name="Line 1"
                  y2="70"
                  transform="translate(647.188 253.312) rotate(45)"
                  fill="none"
                  stroke="#f61111"
                  stroke-width="11"
                />
                <line
                  id="Line_2"
                  data-name="Line 2"
                  y2="70"
                  transform="translate(597.69 253.312) rotate(-45)"
                  fill="none"
                  stroke="#f61111"
                  stroke-width="11"
                />
              </g>
            </svg>
          </a>
          <div className={`${DetailCss.overlayWindow}`}>
            <div className={`${DetailCss.setBg}`}>
              {currentStep === 1 && <DataProcessingModal />}
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            Name: "",
            Email: "",
            Subject: "",
            Message: "",
            UserAgreement: false,
          }}
          validationSchema={Yup.object().shape({
            Name: Yup.string()
              .min(2, "at least 2 characters")
              .max(20, "too long")
              .required("Value is required and can't be empty"),
            Email: Yup.string()
              .email("invalid email")
              .required("Value is required and can't be empty"),
            Subject: Yup.string()
              .min(5, "at least 5 characters")
              .max(20, "too long")
              .required("Value is required and can't be empty"),
            Message: Yup.string()
              .min(5, "at least 5 characters")
              .max(100, "too long")
              .required("Value is required and can't be empty"),
            UserAgreement: Yup.bool().oneOf(
              [true],
              "Please read and confirm the agreement above"
            ),
          })}
          onSubmit={(values) => {
            alert("a");
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <h1>Contact us</h1>
              <Field
                class="bg-transparent text-lg text-gray-300  mt-10 border-0 focus:outline-none border-b border-gray-600  mb-2 w-full"
                type="text"
                id="Name"
                name="Name"
                as=""
                placeholder="Your Name"
              />
              <hr />
              <ErrorMessage
                className={`${ContactUsCss.ErrorMessage}`}
                name="Name"
                component="div"
              />

              <Field
                class="bg-transparent text-lg text-gray-300  mt-10 border-0 focus:outline-none border-b border-gray-600  mb-2 w-full"
                type="text"
                id="Email"
                name="Email"
                as=""
                placeholder="Email"
              />
              <hr />
              <ErrorMessage
                className={`${ContactUsCss.ErrorMessage}`}
                name="Email"
                component="div"
              />
              <Field
                class="bg-transparent text-lg text-gray-300  mt-10 border-0 focus:outline-none border-b border-gray-600  mb-2 w-full"
                type="text"
                id="Subject"
                name="Subject"
                as=""
                placeholder="Subject"
              />
              <hr />
              <ErrorMessage
                className={`${ContactUsCss.ErrorMessage}`}
                name="Subject"
                component="div"
              />
              <Field
                class="bg-transparent text-lg text-gray-300  mt-10 border-0 focus:outline-none border-b border-gray-600  mb-2 w-full"
                type="text"
                id="Message"
                name="Message"
                as=""
                placeholder="Message"
              />
              <hr />
              <ErrorMessage
                className={`${ContactUsCss.ErrorMessage}`}
                name="Message"
                component="div"
              />
              <p>
                <Field
                  type={"checkbox"}
                  id={"UserAgreement"}
                  name={"UserAgreement"}
                  as=""
                  placeholder=""
                />
                {"  "}I agree to the{" "}
                <span
                  className={`${ContactUsCss.DataProcessing}`}
                  onClick={() => {
                    setShow(true);
                    setStep(1);
                  }}
                >
                  processing of personal data
                </span>
                <ErrorMessage
                  className={`${ContactUsCss.ErrorMessage}`}
                  name="UserAgreement"
                  component="div"
                />
                <br />
                <input
                  className={`${ContactUsCss.Submit}`}
                  type="submit"
                  value="SUBMIT"
                />
              </p>
            </Form>
          )}
        </Formik>
      </div>
      <ContactUsFooter />
    </div>
  );
};

export default ContactUs;
