import React, { useContext, useState } from "react";
import ModalStepContext from "../../../../core/context/ModalStepContext";
import ModalFooter from "../ModalFooter";
import ModalCss from "../Modal.module.css";
import AboutUsCss from "./DataProcessingModal.module.css";
import InvestLogo from "../../../../assets/img/W_INVEST.png";
import ModalShowContext from "../../../../core/context/ModalShowContext";
import Input from "../../Input/InputOld";
import TextereaInput from "../../../common/Input/TextereaInput.jsx";
import SelectInput from "../../../common/Input/SelectInput.jsx";
import { ArrowDown } from "react-feather";

const DataProcessingModal = () => {
  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody} py-5`}>
            <h2 className={`${AboutUsCss.privacyHeader}`}></h2>
            <div className={`mt-5 mb-3 ${AboutUsCss.privacyTextHolder}`}>
              <h3 className="p-0">Personal Data Protection Policy</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                DIACCO GROUP SE, based Paťanka 2722/11b, Dejvice, 160 00 Prague
                6, Czechia, registration number 06939864, Maintained by the
                Municipal Court in Prague, Section H, File No. 2105 (the
                “Operator”), undertakes to protect and respect your privacy.
                This Personal Data Protection Policy sets out the basic
                principles used to process the personal data that we collect
                from you or that you provide to us. Please read the following
                information carefully in order to understand how we view
                personal data, what procedures we employ, and how we treat such
                data.
                <br />
                <br />
                The Operator is careful to protect the personal data you
                disclose to us. It is important for you to know that the
                personal data you provide to us is processed responsibly,
                transparently and in accordance with Regulation (EU) No 2016/679
                of the European Parliament and of the Council. You may request
                information on the personal data we have on file, and for such
                data to be rectified and erased, if it has been provided with
                your consent. If your personal data undergoes automated
                processing, you have the right to data portability and not to be
                subject to a decision based solely on such automated processing.
                If you have any questions or requests regarding the processing
                of your personal data, you may contact us in writing at the
                address of the company’s registered office.
              </p>
              <h3 className="p-0">The data collected</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                After you enter your personal data in the contact form on our
                website at https://www.mantacommodities.cz/, the Operator will
                become your personal data controller. The consent you give us
                forms a legal basis for us to record your Email Address, Given
                Name, Surname, and Telephone Number until you withdraw such
                consent. We process that data in order to communicate with you
                on the subject you have indicated in the form.
              </p>
              <h3 className="p-0">IP addresses</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                The Operator may collect information about your computer, such
                as the IP address, the operating system and the browser type (if
                available), in order to manage the system and analyse web
                broadcasts. This data on user behaviour patterns during browsing
                cannot be used to identify any specific person.
              </p>
              <h3 className="p-0">Where do we store your data?</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                We have taken appropriate technical and organisational measures
                so that your data is sufficiently protected, taking into account
                how serious this data processing is. No unauthorised persons
                have access to the personal data we have obtained from you, nor
                do we transfer such data without your consent to other entities
                for further processing, unless required by law or in order to
                protect our legal interests.
                <br />
                <br />
                What we use the data for The information you have provided to us
                is used as described below.
                <ul>
                  <li>
                    On the basis of your consent, which you may withdraw at any
                    time: To send you news and information on special offers
                    (subscription to a newsletter)
                  </li>
                  <li>
                    On the basis of our legitimate interests: To ensure that the
                    content of our website is published in a way that is as
                    efficient as possible for you and your computer
                  </li>
                  <li>For the running of our order management system</li>
                </ul>
              </p>
              <h3 className="p-0">Security of your personal data</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                The Operator is careful to ensure the security of the personal
                data you disclose to us. We have taken appropriate technical and
                organisational measures so that your data is sufficiently
                protected, taking into account how serious this data processing
                is. No unauthorised persons have access to the personal data we
                have obtained from you, nor do we transfer such data without
                your consent to other entities for further processing, unless
                required by law or in order to protect our legal interests.
              </p>
              <h3 className="p-0">Right to information</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                You have the right to request the Operator for information about
                the personal data we process about you, to what extent it is
                processed, and for what purpose. We will provide you with this
                information free of charge within 30 days, and in exceptional
                circumstances within 90 days. We will notify you in good time of
                any extension to that time limit in exceptional cases. If you
                request us to disclose the information we have registered about
                you, we will first need to verify that you are genuinely the
                person to whom this information belongs. With this in mind,
                please identify yourself sufficiently in your request. Where
                necessary, we have the right to solicit additional information
                in order to identify you before we provide you with the personal
                data we process about you.
                <br />
                <br />
                We are entitled to justifiably refuse requests for information
                that are baseless, that are unreasonably repeated, or that
                require undue effort to recover or would be difficult to obtain
                (typically from backup systems, archival documents, etc.).
              </p>
              <h3 className="p-0">Right to data portability</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                You have the right to receive personal data that we have
                recorded about you in a structured, customarily used and
                machine-readable format. If you so request, this data may be
                forwarded to another controller.
              </p>
              <h3 className="p-0">Date updates, right to rectification</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                Date updates, right to rectification As personal data may change
                over time (e.g. there may be a change of Surname), we would be
                grateful if you could notify us of any such changes so that we
                can update your personal data and avoid any errors. It is
                essential for us to be notified of any change in data so that we
                can carry out our Controller activities properly.
                <br />
                <br />
                In this respect, you have the right to the rectification of the
                personal data we have recorded about you. If you discover that
                the data we have on record is no longer current, you have the
                right to request rectification.
              </p>
              <h3 className="p-0">Objections</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                If you believe that we are not processing your personal data in
                accordance with applicable Czech and EU law, you have the right
                to object, in which case we will then check whether your
                concerns are legitimate. When you raise an objection, the
                processing of your personal data will be restricted until we
                have determined whether the objection is justified. Please note
                that you also have the right to refer any objections you may
                have regarding the personal data processed about you to the
                competent supervisory authority, i.e. the Office for Personal
                Data Protection, at: Office for Personal Data Protection Pplk.
                Sochora 27, 170 00 Praha 7
              </p>
              <h3 className="p-0">Right to restriction of processing</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                It is your right to have the processing of your personal data
                restricted if you believe that the personal data we have on
                record is inaccurate, that we are processing your personal data
                unlawfully, or that we no longer require such data for
                processing.
              </p>
              <h3 className="p-0">Right to erasure</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                If you have given us consent to process your personal data (e.g.
                an email address for the dispatch of newsletters), you may
                withdraw that consent at any time and we are then required to
                erase the data that we process solely on the basis of your
                consent. The right to erasure does not apply to data processed
                in the course of contractual performance, on legal grounds, or
                in keeping with our legitimate interests. If any of your data is
                stored in backup systems that automatically ensure the
                resilience of all of our systems and are designed to protect
                data loss in the event of disaster, we are unable to erase such
                data from backup systems. In fact, this is often technically
                infeasible. Nevertheless, this data is no longer actively
                processed in any way and will not be used for further processing
                purposes.
              </p>
              <h3 className="p-0">Who you can contact</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                If you have any questions regarding personal data protection,
                you may also contact our registered office at: DIACCO GROUP SE,
                based Paťanka 2722/11b, Dejvice, 160 00 Prague
                <br />
                <br />
                When you access and browse our website, we process the following
                log files and store them on our servers. The information we
                store includes:
                <ul>
                  <li>Your IP address</li>
                  <li>The pages of our website that you have opened</li>
                  <li>The http response status code</li>
                  <li>The identification of your browser</li>
                  <li>
                    We process this information for a maximum of one year, and
                    do so solely for our own legal protection.
                  </li>
                </ul>
              </p>
              <h3 className="p-0">Cookies</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                Our website uses cookies. A cookie is a small text file that is
                stored on your computer (if you so consent). Those cookies
                enable us to differentiate you from other website users and
                adapt our website activity to improve your browsing experience.
                It also enables us to enhance the website. Our cookie policy
                provides comprehensive information on the types of cookies we
                use and why we use them. By continuing to use this website, you
                consent to the use of cookies.
              </p>
              <h3 className="p-0">Our website uses four cookie categories:</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                <p>
                  <strong>Essential cookies </strong>These are cookies needed by
                  the website to work. They include cookies that enable you to
                  reach the right pages if we update the website functioning in
                  anyway.
                </p>
                <p>
                  <strong>Session cookies </strong>These cookies collect
                  information about how visitors use the website. If any errors
                  occur, they help us to understand what has happened, and they
                  enable us to try out new features. We do not collect any
                  information that could identify visitors directly. The data is
                  aggregated and anonymous. We use this information to improve
                  the website’s functionality.
                </p>
                <h6 className="p-0">Persistent cookies</h6>
                <p className={`${AboutUsCss.privacyText}`}>
                  These cookies record your visits to the website, the pages you
                  visited, and the links you have used. We use this information
                  so that our website better reflects what is of interest to
                  you. Please note that cookies are also used by third parties
                  (web traffic analysers). We have no control over these
                  cookies. It can be assumed that these cookies belong among
                  session cookies or persistent cookies.
                  <br />
                  If you wish to disable cookies, please look up the information
                  in your browser’s help feature.
                </p>
              </p>
              <h3 className="p-0">Analysis and statistics</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                We monitor and analyse the website by means of analytical
                services. None of the data we use for analysis via this service
                includes your personal data. This service enables us to identify
                visitor rates and geographical data, and provides us with
                information on the browser and operating system you use to
                access our website. We use all of this information for marketing
                purposes, for further improvements to our website and content,
                and for legal protection.
              </p>
              <h3 className="p-0">Emailed newsletters</h3>
              <p className={`${AboutUsCss.privacyText}`}>
                If you wish to receive special offers from the Operator via
                email, please tell us your email address and give consent for us
                to use it. We will use that email address solely for this
                purpose. The Operator does not share email addresses obtained in
                this way with any other entities. If you decide, at any time in
                the future, that you no longer wish to receive emails from the
                Operator for this purpose, you may withdraw your consent to the
                processing of that email address here or by writing to the
                company’s registered office.
              </p>
            </div>
          </div>
        </div>
        <ModalFooter leftItems={false} />
      </div>
    </div>
  );
};

export default DataProcessingModal;
