import React, { useContext } from "react";
import ModalStepContext from "../../../core/context/ModalStepContext";
import ModalCss from "./Modal.module.css";
import DiaccoWhite from "../../../assets/img/diacco_white.png";

const ModalFooter = ({ leftItems }) => {
  const p = useContext(ModalStepContext);

  const handlePrivacyShow = () => {
    p.setStep(4);
  };

  const handlePreContactModalShow = () => {
    p.setStep(6);
  };

  return (
    <div className={`${ModalCss.footerHolder}`}>
      <div className={`${ModalCss.footer}`}>
        {/* {leftItems && (
          <div className={`${ModalCss.needHelp} `}>
            <p className={`${ModalCss.footerText}`}>
              <a
                onClick={() => handlePreContactModalShow()}
                className="text-decoration-underline"
              >
                Contact the event organiser
              </a>
              <br />
              <a
                className="text-decoration-underline"
                onClick={() => handlePrivacyShow()}
              >
                View privacy policy
              </a>
            </p>
          </div>
        )} */}

        <span className={`${ModalCss.powerBy}`}>
          <small>Powered by: </small>
          <br />
          <img src={DiaccoWhite} alt="logo" />
        </span>
      </div>
    </div>
  );
};

export default ModalFooter;
