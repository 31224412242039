import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import headerCss from "./Header.module.css";

const Header = () => {
  const history = useHistory();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleContactUs = () => {
    history.push("/contact_us");
  };
  const handleInvest = () => {
    history.push("/invest");
  };
  const handleLanding = () => {
    history.push("/");
  };
  return (
    <Nav className={`py-2`}>
      <NavItem>
        <NavLink href="#">
          <ButtonDropdown
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className={`${headerCss.button}`}
          >
            <DropdownToggle className={`${headerCss.button}`}>
              <svg
                className={headerCss.menuIcon}
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="17"
                viewBox="0 0 50 17"
              >
                <g
                  id="Group_170"
                  data-name="Group 170"
                  transform="translate(-1728.365 -54)"
                >
                  <rect
                    id="Rectangle_32"
                    data-name="Rectangle 32"
                    width="50"
                    height="2"
                    rx="1"
                    transform="translate(1728.365 54)"
                    fill="#fff"
                  ></rect>
                  <rect
                    id="Rectangle_33"
                    data-name="Rectangle 33"
                    width="50"
                    height="2"
                    rx="1"
                    transform="translate(1728.365 69)"
                    fill="#fff"
                  ></rect>
                </g>
              </svg>
            </DropdownToggle>
            <DropdownMenu className={`${headerCss.menu}`}>
              <DropdownItem
                className={`pt-1 pb-0 ${headerCss.navItem} ${headerCss.navItemstart}`}
                href="/"
                tag="a"
                onClick={handleLanding}
              >
                DIACCO GROUP
              </DropdownItem>
              <DropdownItem
                href="/"
                tag="a"
                onClick={handleInvest}
                className={`py-0 ${headerCss.navItem}`}
              >
                <Link to="/" className={`text-white`}>
                  <span className={`ps-3 pe-2 ${headerCss.navSpan}`}>|</span>{" "}
                  DIACCO INVEST
                </Link>
              </DropdownItem>
              <DropdownItem
                href="/"
                tag="a"
                className={`py-0 ${headerCss.navItem}`}
              >
                <Link to="/" className={`text-white`}>
                  <span className={`ps-3 pe-2 ${headerCss.navSpan}`}>|</span>{" "}
                  DIACCO FLEET
                </Link>
              </DropdownItem>
              {/* <DropdownItem
                href="/"
                tag="a"
                className={`py-0 ${headerCss.navItem}`}
              >
                <Link to="/events" className={`text-white`}>
                  <span className={`ps-3 pe-2 ${headerCss.navSpan}`}>|</span>{" "}
                  DIACCO EVENT
                </Link>
              </DropdownItem> */}
              <DropdownItem
                className={`pb-1 pt-1 ${headerCss.navItem} ${headerCss.navItemend}`}
                href="/"
                tag="a"
                onClick={handleContactUs}
              >
                CONTACT US
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default Header;
