import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import diaccoeventImg from "../../../assets/img/DEvent.png";
import diaccoInvestImg from "../../../assets/img/DInvest.png";
import diaccogroupImg from "../../../assets/img/group.svg";
import diaccofleetImg from "../../../assets/img/DFleet.png";
import NewsSliderCss from "./NewsSlider.module.css";
import "./NewsSlider.css";
import SliderItem from "./SliderItem/SliderItem";
import ModalStepContext from "../../../core/context/ModalStepContext";
import ModalShowContext from "../../../core/context/ModalShowContext";

const NewsSlider = () => {
  const { setStep } = useContext(ModalStepContext);
  const { setShow } = useContext(ModalShowContext);

  let history = useHistory();

  const handleClick = (path) => {
    history.push(`/${path}`);
  };

  return (
    <Swiper
      modules={[Navigation, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={2}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
      className={`${NewsSliderCss.NewsSliderBg}`}
    >
      <SwiperSlide>
        <SliderItem
          ONClick={() => {
            setShow(true);
            setStep(2);
          }}
          title="We invite investors!"
          text="We invite investors to participate in the project of establishing a sports center, beauty salon or restaurant in Prague, we prepare the required documents, we implement all legal rights and permits until your company is fully operational. In case you need an executive or a responsible person in a special field, we will acquaint you with a quality workforce!"
        />
      </SwiperSlide>
      <SwiperSlide>
        <SliderItem
          ONClick={() => {
            setShow(true);
            setStep(3);
          }}
          title="Are you looking for a job?"
          text="If you are interested to be a part of our young and dynamic transportation team (DIACCO FLEET), we welcome you to join us as either “short time agreement” or under “long term contracts” and have the freedom to create your own work schedule. This provides an ideal job opportunity for students or freelancers to earn easy and fast money and cover their cost of living while pursuing their goals. We have solutions for everyone, especially those who don’t have a trade license or simply don't want to deal with the state regulations. Therefore, we might offer you to contact us and we would be happy to discuss with you in detail about your specific circumstances."
        />
      </SwiperSlide>
      <SwiperSlide>
        <SliderItem
          ONClick={() => {
            setShow(true);
            setStep(4);
          }}
          title="Establishing a new joint venture!  "
          text="As of November 18, 2019, we have a new branch subsidiary under file number C 323527 kept at the Municipal Court in Prague."
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default NewsSlider;
