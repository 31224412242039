import React from "react";
import { useHistory } from "react-router-dom";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import diaccoeventImg from "../../assets/img/DEvent.png";
import diaccoInvestImg from "../../assets/img/DInvest.png";
import diaccogroupImg from "../../assets/img/group.svg";
import diaccofleetImg from "../../assets/img/DFleet.png";
import sliderCss from "./Slider.module.css";
import "./Slider.css";

const Slider = () => {
  let history = useHistory();

  const handleClick = (path) => {
    history.push(`/${path}`);
  };
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
      className={`${sliderCss.sliderBg}`}
    >
      <SwiperSlide>
        <div
          className={`d-flex flex-column justify-content-center ${sliderCss.centerBox}`}
        >
          <img
            src={diaccogroupImg}
            className={`d-flex align-self-center ${sliderCss.sliderImg}`}
          />
          <div className={`align-self-center ${sliderCss.sliderCaption}`}>
            <p className={`${sliderCss.sliderText}`}>
              {" "}
              DIACCO GROUP SE is a European joint stock company responsible for
              leading and managing it’s affiliated subsidiaries, which are
              involved in a wide range of spectrum in business development.
            </p>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          className={`d-flex flex-column justify-content-center ${sliderCss.centerBox2}`}
        >
          <img
            src={diaccoInvestImg}
            className={`d-flex align-self-center ${sliderCss.sliderImg}`}
          />
          <div className={`align-self-center ${sliderCss.sliderCaption}`}>
            <p className={`${sliderCss.sliderText}`}>
              {" "}
              DIACCO INVEST is the core of our corporate business ideology. It
              provides administrative services for international trade, domestic
              business development, and ultimately job creations.
            </p>
          </div>
          <button
            onClick={() => handleClick("invest")}
            type="button"
            className={` d-flex align-self-center ${sliderCss.btn}`}
          >
            More details
          </button>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div
          className={`d-flex flex-column justify-content-center ${sliderCss.centerBox2}`}
        >
          <img
            src={diaccofleetImg}
            className={`d-flex align-self-center ${sliderCss.sliderImg}`}
          />
          <div className={`align-self-center ${sliderCss.sliderCaption}`}>
            <p className={`${sliderCss.sliderText}`}>
              {" "}
              DIACCO FLEET is a flexible and experienced team, operating in the
              fields of tourism and transportation. We are official partners of
              Uber, Bolt and multiple travel agencies across the globe.
            </p>
          </div>
          <button
            //  onClick={() => handleClick("fleet")}
            type="button"
            className={` d-flex align-self-center ${sliderCss.btn}`}
          >
            More details
          </button>
        </div>
      </SwiperSlide>
      {/* <SwiperSlide>
        <div
          className={`d-flex flex-column justify-content-center ${sliderCss.centerBox}`}
        >
          <img
            src={diaccoeventImg}
            className={`d-flex align-self-center ${sliderCss.inventImg}`}
          />
          <div className={`align-self-center ${sliderCss.sliderCaption}`}>
            <p className={`${sliderCss.sliderText}`}>
              {" "}
              DIACCO EVENT is a young and dynamic team, operating in the fields
              of events and entertainment. We are official partners of several
              entertainment agencies in Central Europe.
            </p>
          </div>
          <button
            onClick={() => handleClick("events")}
            type="button"
            className={` d-flex align-self-center ${sliderCss.btn}`}
          >
            More details
          </button>
        </div>{" "}
      </SwiperSlide> */}
    </Swiper>
  );
};

export default Slider;
