import React from 'react';
import DiaccoFleetCss from './DiaccoFleet.module.css'

const DiaccoFleet = () => {
    return ( 
        <div className={`bg-dark text-center fw-bolder text-white fs-1`}>
            Diacco Fleet
        </div>
     );
}
 
export default DiaccoFleet;