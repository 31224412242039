import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ModalFooter from "../ModalFooter";
import ModalCss from "../Modal.module.css";
import OrderCss from "./OrderModal.module.css";
import OrderTicketRow from "./OrderTicketRow";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import ModalStepContext from "../../../../core/context/ModalStepContext";
import backArrow from "../../../../assets/img/back-arrow.png";
import Input from "../../Input/Input";
import ModalShowContext from "../../../../core/context/ModalShowContext";

const formSchema = Yup.object().shape({
  Name: Yup.string()
    .min(2, "at least 2 characters")
    .max(20, "too long")
    .required("Value is required and can't be empty"),
  Email: Yup.string()
    .email("invalid email")
    .required("Value is required and can't be empty"),
  EmailConfirm: Yup.string()
    .email("invalid email")
    .required("Value is required and can't be empty")
    .oneOf([Yup.ref("Email"), null], "Emails must match"),
  PhoneNumber: Yup.number(),
  UserAgreement: Yup.bool().oneOf(
    [true],
    "Please read and confirm the agreement below"
  ),
});

const OrderModal = () => {
  const { currentStep, setStep, totalOrder } = useContext(ModalStepContext);
  const { show, setShow } = useContext(ModalShowContext);
  const [error, setError] = useState([]);

  const handleOrderClick = () => {
    setStep(currentStep + 1);
  };

  const handleBack = () => {
    setShow(false);
    console.log("step", currentStep);
  };

  const getSum = (order) => {
    const res = order
      .map((item) => {
        return item.count * item.price;
      })
      .reduce((a, b) => a + b, 0);
    return res;
  };

  const showTermsOfServices = () => {
    setStep(9);
  };

  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody}`}>
            <header className={`${ModalCss.modalHeader}`}>
              <a
                onClick={() => handleBack()}
                className={`${ModalCss.backArrow}`}
                style={{ cursor: "pointer" }}
              >
                <img src={backArrow} alt="" />
              </a>
              <h2 className={`${ModalCss.headerTitle}`}>
                Nowruz Party at One Club, Melantrichova 5, Prague 1
              </h2>
              <h2 className={`${ModalCss.headerTime}`}>
                Fri 18 Mar. 2022, 22:00 - 5:00
              </h2>
              <ul className={`${ModalCss.progress}`}>
                {/* <li>
                  <span className={`${ModalCss.mobileHide}`}>
                    Select tickets
                  </span>
                  <span className={`${ModalCss.mobileOnly}`}>Tickets</span>
                </li> */}
                <li className={`${ModalCss.active}`}>Your details</li>
                <li>Payment</li>
              </ul>
            </header>

            <h3>Your order</h3>
            <table className={`${OrderCss.summary}`}>
              <thead>
                <tr className={`${OrderCss.eventName}`}>
                  <th colSpan={4}>
                    <span className={`${OrderCss.name}`}>
                      Nowruz Party at One Club, Melantrichova 5, Prague 1
                    </span>
                    <span className={`${OrderCss.date}`}>
                      Fri 18 Mar. 2022, 22:00 - 5:00
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {totalOrder.map((item) => (
                  <OrderTicketRow
                    name={item.name}
                    price={item.price}
                    quantity={item.count}
                  />
                ))}
              </tbody>
              <tfoot>
                <tr className={`${OrderCss.total}`}>
                  <td colSpan={3}>Order total</td>
                  <td className="text-end">{getSum(totalOrder)},- CZK</td>
                </tr>
              </tfoot>
            </table>
            <div className={`${OrderCss.formHolder}`}>
              <h3>Please complete the form below</h3>
              <div className={`${OrderCss.checkoutForm}`}>
                <Formik
                  initialValues={{
                    Name: "",
                    Email: "",
                    EmailConfirm: "",
                    Number: "",
                    UserAgreement: false,
                  }}
                  validationSchema={formSchema}
                  onSubmit={(values) => {
                    values.UserAgreement && handleOrderClick();
                  }}
                >
                  {({ errors, touched }) => (
                    <div>
                      <Form>
                        <dl className={`${OrderCss.zendForm}`}>
                          <Input
                            name="Name"
                            type="text"
                            inputLabel="Name"
                            element="name-element"
                            errorMsg=""
                            required={true}
                          />
                          <Input
                            name="Email"
                            type="email"
                            inputLabel="Email"
                            element="email-element"
                            errorMsg=""
                            required={true}
                          />
                          <Input
                            name="EmailConfirm"
                            type="email"
                            inputLabel="Repeat Email"
                            element="email-confirm-element"
                            errorMsg=""
                            required={true}
                          />
                          <Input
                            name="PhoneNumber"
                            type="text"
                            inputLabel="Phone number"
                            element="number-element"
                            errorMsg=""
                            required={false}
                          />

                          <div className={`${OrderCss.inputHolder}`}>
                            <Field
                              className={`${OrderCss.inputItem}`}
                              type={"checkbox"}
                              id={"UserAgreement"}
                              name={"UserAgreement"}
                              as=""
                              placeholder=""
                            />

                            <label
                              className={OrderCss.required}
                              htmlFor={"UserAgreement"}
                            >
                              I agree to the{" "}
                              <span
                                onClick={showTermsOfServices}
                                style={{
                                  TextDecoration: "underline",
                                  color: "blue",
                                  cursor: "pointer",
                                }}
                              >
                                terms of services
                              </span>
                            </label>
                            <ErrorMessage
                              name={"UserAgreement"}
                              component="div"
                              className={`${OrderCss.inputError} text-danger`}
                            />
                          </div>
                          <div className={`${OrderCss.inputHolder}`}>
                            <dd
                              id="submit-element"
                              className={`${OrderCss.submitElement}`}
                            >
                              <input
                                className={`${OrderCss.submitItem}`}
                                type="submit"
                                id="submit"
                                name="submit"
                                value="Next"
                              />
                            </dd>
                          </div>
                        </dl>
                      </Form>
                    </div>
                  )}
                </Formik>
                <br className={`${OrderCss.spr}`} />
              </div>
            </div>
          </div>
        </div>

        <ModalFooter leftItems={true} />
      </div>
    </div>
  );
};

export default OrderModal;
