import React, { useState } from 'react';
import { Nav, NavItem, NavLink, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import StudyImg from '../../../assets/img/DStudy.png';
import studyHeaderCss from "./DiaccoStudyHeader.module.css";


const DiaccoStudyHeader = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false)

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen)
    }
    return (
        <Nav className={`d-flex justify-content-between ${studyHeaderCss.navBg}`}>
            <NavItem>
                <NavLink href='#'>
                    <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown} className={`${studyHeaderCss.button}`}>
                        <DropdownToggle className={`${studyHeaderCss.button}`}>
                            <svg className={`${studyHeaderCss.menuIcon}`} xmlns="http://www.w3.org/2000/svg" width="128.923" height="75.935" viewBox="0 0 128.923 75.935">
                                <g id="Group_1" data-name="Group 1" transform="translate(-475 -407)">
                                    <path id="Path_1" data-name="Path 1" d="M0,0H128.923V75.935H0Z" transform="translate(475 407)" fill="#3d424a" />
                                    <line id="Line_1" data-name="Line 1" x2="95.049" transform="translate(490.921 430.877)" fill="none" stroke="#fff" stroke-width="3" />
                                    <line id="Line_2" data-name="Line 2" x2="95.049" transform="translate(490.921 459.059)" fill="none" stroke="#fff" stroke-width="3" />
                                </g>
                            </svg>
                        </DropdownToggle>
                        <DropdownMenu className={`text-uppercase ${studyHeaderCss.menu}`}>
                            <DropdownItem className={`pt-1 pb-0 mb-2 ${studyHeaderCss.navItem} ${studyHeaderCss.navItemstart}`} href='/' tag='a'>DIACCo GROUP</DropdownItem>
                            <DropdownItem href='/' tag='a' className={`py-0 mb-2 ${studyHeaderCss.navItem}`}>
                                <Link to="/invest" className={`text-white`}>
                                    <span className={`ps-3 pe-2 ${studyHeaderCss.navSpan}`}>|</span>  DIACCO INVEST
                                </Link>
                            </DropdownItem>
                            <DropdownItem href='/' tag='a' className={`py-0 mb-2 ${studyHeaderCss.navItem}`}>
                                <Link to="/fleet" className={`text-white`}>
                                    <span className={`ps-3 pe-2 ${studyHeaderCss.navSpan}`}>|</span>  DIACCO FLEET
                                </Link>
                            </DropdownItem>
                            <DropdownItem href='/' tag='a' className={`py-0 mb-2 ${studyHeaderCss.navItem}`}>
                                <Link to="/study" className={`text-white`}>
                                    <span className={`ps-3 pe-2 ${studyHeaderCss.navSpan}`}>|</span>  DIACCO STUDY
                                </Link>
                            </DropdownItem>
                            <DropdownItem href='/' tag='a' className={`py-0 mb-1 ${studyHeaderCss.navItem}`}>
                                <Link to="/events" className={`text-white`}>
                                    <span className={`ps-3 pe-2 ${studyHeaderCss.navSpan}`}>|</span>  DIACCO EVENT
                                </Link>
                            </DropdownItem>
                            <DropdownItem className={`pb-1 pt-1 ${studyHeaderCss.navItem} ${studyHeaderCss.navItemend}`} href='/' tag='a'>CONTACT US</DropdownItem>
                        </DropdownMenu>
                    </ButtonDropdown>
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink>
                    <img src={StudyImg} className={`${studyHeaderCss.logo}`} alt="" />
                </NavLink>
            </NavItem>

        </Nav>
    );
}

export default DiaccoStudyHeader;