import React from "react";
import SliderItemCss from "./SliderItem.module.css";
import Invite from "../../../../assets/img/invite.png";

const SliderItem = ({ title, text, ONClick }) => {
  return (
    <>
      <div
        onClick={ONClick}
        className={` ${SliderItemCss.Item} shadow-lg cursor-pointer mt-20 flex-col news-card flex  items-center  rounded-lg `}
      >
        <img src={Invite} alt="" />
        <h1 class="font-bold cursor-pointer">{title}</h1>
        <p class="text-sm  text-news text-center font-medium  overflow-ellipsis">
          {text}
        </p>
      </div>
    </>
  );
};

export default SliderItem;
