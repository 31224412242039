import React from "react";
import DiaccoLogo from "../../../assets/img/group.svg";
import ContactUsFooterCss from "./ContactUsFooter.module.css";

const ContactUsFooter = () => {
  return (
    <div className={`${ContactUsFooterCss.ContactUsFooterBg}`}>
      <div>
        <img
          className={`${ContactUsFooterCss.DiaccoLogo}`}
          style={{ width: "186px;" }}
          src={DiaccoLogo}
          alt=""
        />

        <span className={`${ContactUsFooterCss.DiaccoDetails}`}>
          DIACCO GROUP SE
          <br />
          Paťanka 2722/11b, Dejvice, 160 00 Prague 6, Czechia
          <br />
          VAT ID: CZ06939864
        </span>

        <div className={`${ContactUsFooterCss.Logos}`}>
          <a href="/felan">
            <svg
              className="icon-link"
              xmlns="http://www.w3.org/2000/svg"
              width="44.444"
              height="36.111"
              viewBox="0 0 44.444 36.111"
            >
              <g id="twitter" transform="translate(-114 100)">
                <g
                  id="Group_28"
                  data-name="Group 28"
                  transform="translate(114 -100)"
                >
                  <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M44.444,52.275a19,19,0,0,1-5.25,1.439A9.06,9.06,0,0,0,43.2,48.678a18.209,18.209,0,0,1-5.778,2.206,9.111,9.111,0,0,0-15.761,6.231,9.382,9.382,0,0,0,.211,2.078A25.79,25.79,0,0,1,3.094,49.661a9.114,9.114,0,0,0,2.8,12.178,9,9,0,0,1-4.117-1.122v.1a9.153,9.153,0,0,0,7.3,8.953,9.094,9.094,0,0,1-2.389.3,8.056,8.056,0,0,1-1.725-.156,9.2,9.2,0,0,0,8.514,6.347,18.308,18.308,0,0,1-11.3,3.886A17.066,17.066,0,0,1,0,80.022a25.651,25.651,0,0,0,13.978,4.089c16.767,0,25.933-13.889,25.933-25.928,0-.4-.014-.792-.033-1.178A18.177,18.177,0,0,0,44.444,52.275Z"
                    transform="translate(0 -48)"
                    fill="#fff"
                  ></path>
                </g>
              </g>
            </svg>
          </a>
          <a href="/felan">
            <svg
              className="icon-link"
              xmlns="http://www.w3.org/2000/svg"
              width="41.001"
              height="41"
              viewBox="0 0 41.001 41"
            >
              <path
                id="Icon_awesome-linkedin-in"
                data-name="Icon awesome-linkedin-in"
                d="M9.178,41H.677V13.627h8.5ZM4.923,9.893a4.946,4.946,0,1,1,4.923-4.97A4.964,4.964,0,0,1,4.923,9.893ZM40.992,41H32.51V27.675c0-3.176-.064-7.248-4.419-7.248-4.419,0-5.1,3.45-5.1,7.02V41H14.5V13.627h8.153v3.734h.119a8.932,8.932,0,0,1,8.043-4.42c8.6,0,10.184,5.665,10.184,13.023V41Z"
                transform="translate(0 0)"
                fill="#fff"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUsFooter;
