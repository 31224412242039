import React from 'react';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import StudyCss from './DiaccoStudy.module.css';

import DiaccoStudyHeader from '../Header/DiaccoStudyHeader/DiaccoSudyHeader';

const formSchema = Yup.object().shape({
    name: Yup.string().max(20, "بیشتر از 200 حرف").required("پر کردن فیلد الزامیست"),
    email: Yup.string().email(" آدرس ایمیل نامعتبر").required("پر کردن فیلد الزامیست"),
    comment: Yup.string().max(200, "بیشتر از 200 حرف").required("پر کردن فیلد الزامیست"),
});

const DiaccoStudy = () => {
    return (
        <div className={`w-screen row ${StudyCss.studyBg}`}>
            <div className={`m-auto pt-5 row w-75 ${StudyCss.topRow}`}>
                <DiaccoStudyHeader />
            </div>
            <div className={`row w-75 m-auto ${StudyCss.bottomRow}`}>
                <div className={`row w-75 m-auto ${StudyCss.textBox}`}>
                    <h2 className={`text-center font-weight-bold ${StudyCss.studyHeading}`}>تحصیل در جمهوری چک</h2>
                    <p className={`${StudyCss.studyText}`}>
                        دایاكو، نماینده رسمى کالج هاى معتبر آموزش زبان در جمهورى چك میباشد، این كالج ها مجاز به برگزارى آزمون هاى تعیین سطح زبان چک برای دانشجویان خارجی مایل به تحصیل رایگان در دانشگاه های دولتى چك میباشند. ما با معرفى هنرجویان به مدارس زبان مناسب، به متقاضیان علاقه مند به تحصیل در دانشگاه های معتبر کمک می کنیم تا در قلب اروپا بصورت رایگان به تحصیل بپردازند.
                        <br />
                        تنها نیاز شما برای بهره بردن از امكان تحصیلى رایگان در دانشگاههاى دولتى جمهورى چك، آشنایى با زبان چک در سطح بى دو میباشد. براى رسیدن به این هدف، كافى است که یك دوره یکساله فراگیرى زبان چك را در شهر پراگ سپرى كنید!
                        <br />
                        ما با استقرار در پراگ، از تجربه اى طولانى و عینى تحصیل و زندگى دراین شهر برخوردارهستیم و بهره ورى شما از خدمات مشاوره تحصیلى دایاكو، نه تنها منجر به انتخاب صحیح دانشگاه و كالج معتبر خواهد شد بلکه داراى مزیت هاى دیگرى از قبیل؛ كسب اطلاعات كافى نسبت به مراحل اخذ ویزاى تحصیلى، دقت و صرفه جویى زمانى حین تهیه مداركى که به سفارت ارائه میدهید و واریز بموقع وجه شهریه است که همگى شانس موفقیت شما در اخذ ویزا را افزایش یا هزینه هاى جانبى فرایند مهاجرت شما را كاهش میدهد
                    </p>
                    <h2 className={`text-center font-weight-bold ${StudyCss.contactUs}`}> تماس با ما</h2>

                    <div className="row p-0 m-auto">
                        <Formik
                            initialValues={{
                                name: "",
                                email: "",
                                comment: "",
                            }}
                            validationSchema={formSchema}
                            onSubmit={(values) => {
                                const commentObj = {
                                    name: "",
                                    email: "",
                                    comment: "",

                                }
                            }}
                        >
                            {({ isSubmitting, errors, touched }) => (
                                <form>
                                    <input type="text" placeholder="نام شما" className={`border-0 ${StudyCss.input}`} />
                                    <input type="email" placeholder="ایمیل" className={`border-0 ${StudyCss.input}`} />
                                    <input type="text" placeholder="پیام" className={`border-0 ${StudyCss.input}`} />

                                    <div className={`d-flex justify-content-center ${StudyCss.submitBox}`}>
                                        <input type="checkbox" id="agree" name="agree" value="agree" className="ms-1" />
                                        <label htmlFor="">
                                        من موافق  
                                        <span className={`mx-2 ${StudyCss.textBlue}`}>
                                        پردازش اطلاعات وارد شده 
                                        </span>
                                        می باشم  
                                        </label>
                                    </div>
                                    <div className="d-flex mt-2 mb-5 pb-5 justify-content-center">
                                        <button className={`btn ${StudyCss.submitBtn}`}> ارسال</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DiaccoStudy;