import React, { useContext, useState } from "react";
import ModalStepContext from "../../../../core/context/ModalStepContext";
import ModalFooter from "../ModalFooter";
import ModalCss from "../Modal.module.css";
import AboutUsCss from "./EstablishModal.module.css";
import InvestLogo from "../../../../assets/img/W_INVEST.png";
import ModalShowContext from "../../../../core/context/ModalShowContext";
import Input from "../../Input/InputOld";
import TextereaInput from "../../../common/Input/TextereaInput.jsx";
import SelectInput from "../../../common/Input/SelectInput.jsx";
import { ArrowDown } from "react-feather";

const EstablishModal = () => {
  const { currentStep, setStep } = useContext(ModalStepContext);
  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody} py-5`}>
            <h2 className={`${AboutUsCss.invitationHeader}`}></h2>
            <div className={`mt-5 mb-3 ${AboutUsCss.invitationTextHolder}`}>
              <h3 className="p-0 text-center">
                Establishing a new joint venture!
              </h3>
              <p className={`${AboutUsCss.invitationText}`}>
                As of November 18, 2019, we have a new branch subsidiary under
                file number C 323527 kept at the Municipal Court in Prague.
              </p>
              <p className={`${AboutUsCss.Date}`}>Created at : 18.11.2019</p>
            </div>
          </div>
        </div>
        <ModalFooter leftItems={false} />
      </div>
    </div>
  );
};

export default EstablishModal;
