import React, { useContext, useState } from "react";
import ModalStepContext from "../../../../core/context/ModalStepContext";
import ModalFooter from "../ModalFooter";
import ModalCss from "../Modal.module.css";
import AboutUsCss from "./JobModal.module.css";
import InvestLogo from "../../../../assets/img/W_INVEST.png";
import ModalShowContext from "../../../../core/context/ModalShowContext";
import Input from "../../Input/InputOld";
import TextereaInput from "../../../common/Input/TextereaInput.jsx";
import SelectInput from "../../../common/Input/SelectInput.jsx";
import { ArrowDown } from "react-feather";

const JobModal = () => {
  const { currentStep, setStep } = useContext(ModalStepContext);
  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody} py-5`}>
            <h2 className={`${AboutUsCss.invitationHeader}`}></h2>
            <div className={`mt-5 mb-3 ${AboutUsCss.invitationTextHolder}`}>
              <h3 className="p-0 text-center">Are you looking for a job?</h3>
              <p className={`${AboutUsCss.invitationText}`}>
                If you are interested to be a part of our young and dynamic
                transportation team (DIACCO FLEET), we welcome you to join us as
                either “short time agreement” or under “long term contracts” and
                have the freedom to create your own work schedule. This provides
                an ideal job opportunity for students or freelancers to earn
                easy and fast money and cover their cost of living while
                pursuing their goals. We have solutions for everyone, especially
                those who don’t have a trade license or simply don't want to
                deal with the state regulations. Therefore, we might offer you
                to contact us and we would be happy to discuss with you in
                detail about your specific circumstances.
              </p>
              <p className={`${AboutUsCss.Date}`}>Created at : 01.10.2020</p>
            </div>
          </div>
        </div>
        <ModalFooter leftItems={false} />
      </div>
    </div>
  );
};

export default JobModal;
