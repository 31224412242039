import React, { useContext, useState } from "react";
import ModalStepContext from "../../../../core/context/ModalStepContext";
import ModalFooter from "../ModalFooter";
import ModalCss from "../Modal.module.css";
import AboutUsCss from "./InvitationModal.module.css";
import InvestLogo from "../../../../assets/img/W_INVEST.png";
import ModalShowContext from "../../../../core/context/ModalShowContext";
import Input from "../../Input/InputOld";
import TextereaInput from "../../../common/Input/TextereaInput.jsx";
import SelectInput from "../../../common/Input/SelectInput.jsx";
import { ArrowDown } from "react-feather";

const InvitationModal = () => {
  const { currentStep, setStep } = useContext(ModalStepContext);
  return (
    <div className={`${ModalCss.windowHolder}`}>
      <div className={`${ModalCss.shopContainer}`}>
        <div className={`${ModalCss.modalBodyWraper}`}>
          <div className={`${ModalCss.modalBody} py-5`}>
            <h2 className={`${AboutUsCss.invitationHeader}`}></h2>
            <div className={`mt-5 mb-3 ${AboutUsCss.invitationTextHolder}`}>
              <h3 className="p-0 text-center">We invite investors!</h3>
              <p className={`${AboutUsCss.invitationText}`}>
                We invite investors to participate in the project of
                establishing a sports center, beauty salon or restaurant in
                Prague, we prepare the required documents, we implement all
                legal rights and permits until your company is fully
                operational. In case you need an executive or a responsible
                person in a special field, we will acquaint you with a quality
                workforce!
              </p>
              <p className={`${AboutUsCss.Date}`}>Created at : 10.03.2021</p>
            </div>
          </div>
        </div>
        <ModalFooter leftItems={false} />
      </div>
    </div>
  );
};

export default InvitationModal;
